import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import styles from "./about.module.sass"
import { At } from '@styled-icons/boxicons-regular'
import { Radio } from '@styled-icons/feather'

const Sketch = () => (
  <Layout>
    <SEO title="Sketch" />
    
    <article className={styles.about}>
      <header className={styles.about_header}>
        <h1 className={styles.about_title}>Sketch</h1>
      </header>

      {/* ---- About Me ---- */}
      <section className={styles.about_section}>
        <h4  style={{ textAlign:`center`, fontWeight:`100`, margin:`3rem 0`}}>
          This page is currently under construction... Stay tuned  <br /><br /><Radio size="26" />
        </h4>

      </section>

    </article>

  </Layout>
)

export default Sketch
